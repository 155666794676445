import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {useParams} from "react-router-dom";
import {NEW_SUBLET, SubletSection} from "@renaissancerentals/renaissance-component";
import {MainLayout} from "../components/layout/MainLayout";

export const SubletPage: React.FC = () => {
    const subletParam = useParams();
    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);


    return (
        <>
            <Helmet>
                <title>Renaissance Rentals: Sublets:{subletParam.uniqueId}</title>
            </Helmet>
            <MainLayout isMainPage={false}
                        showApplicationModal={showApplicationModal} showContactModal={showContactModal}
                        setShowApplicationModal={setShowApplicationModal} setShowContactModal={setShowContactModal}>
                <SubletSection uniqueId={NEW_SUBLET}/>
            </MainLayout>
        </>

    );
}
