import React, {useState} from 'react';
import './assets/MainHeader.scss';
import {Logo} from "../../logo/Logo";
import {HeaderNav} from "../../nav/HeaderNav";
import {NavProps} from "../../nav/NavProps";
import {Button, Icon, Modal} from "@contentmunch/muncher-ui";
import {trackContactClicked} from "@renaissancerentals/renaissance-component";

export const MainHeader: React.FC<MainHeaderProps> = (
    {
        isMainPage,
        setShowApplicationModal,
        setShowContactModal,
        handleRefToSearch,
        handleRefToTop
    }) => {

    const [showModal, setShowModal] = useState(false);


    return (
        <header className="header-main">
            <div className="div-mobile">
                <div className="header">
                    <Modal show={showModal} setShow={setShowModal}>
                        <HeaderNav setShowApplicationModal={setShowApplicationModal}
                                   setShowContactModal={setShowContactModal}
                                   handleRefToSearch={handleRefToSearch}
                                   isMainPage={isMainPage}
                                   modalClose={() => {
                                       setShowModal(false);
                                       setShowApplicationModal(false);
                                       setShowContactModal(false);
                                   }}/>
                    </Modal>
                    <div className="left">
                        <div className="hamburger" onClick={() => {
                            setShowModal(true);
                        }}>
                            <Icon name="hamburger" size="medium"/>
                        </div>
                    </div>
                    <Logo handleRefToTop={handleRefToTop} isMainPage={isMainPage}/>
                    <div className="right">
                        <Button onClick={() => {
                            setShowContactModal(true);
                            trackContactClicked("apartments-in-bloomington");
                        }} variant="transparent" title="Contact us">
                            <Icon name="mail" size="small"/>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="div-main">
                <div className="header">
                    <div className="left"/>
                    <HeaderNav setShowApplicationModal={setShowApplicationModal}
                               setShowContactModal={setShowContactModal}
                               isMainPage={isMainPage}
                               handleRefToSearch={handleRefToSearch}
                               modalClose={() => {
                                   setShowModal(false)
                               }}>
                        <Logo handleRefToTop={handleRefToTop} isMainPage={isMainPage}/>
                    </HeaderNav>
                    <div className="right"/>
                </div>
            </div>
        </header>
    )
};

export interface MainHeaderProps extends NavProps {
    handleRefToTop: () => void;
}
