import React from 'react';
import {NavProps} from "./NavProps";
import {trackContactClicked} from "@renaissancerentals/renaissance-component";

export const HeaderNav: React.FC<HeaderNavProps> = (
    {
        setShowApplicationModal, setShowContactModal, modalClose, handleRefToSearch,
        isMainPage, children
    }) => {

    return (
        <nav>
            <h3><a href={isMainPage ? "#search" : "/#search"} onClick={() => {
                if (handleRefToSearch) {
                    console.log("yes");
                    modalClose();
                    handleRefToSearch();
                }

            }}>search</a></h3>
            <h3><a href="#apply" onClick={() => {
                modalClose();
                setShowApplicationModal(true);

            }}>apply</a></h3>

            {children}

            <h3><a href="/sublets" onClick={() => {
                modalClose();
                setShowApplicationModal(true);

            }}>sublets</a></h3>
            <h3><a href="#contact" onClick={() => {
                modalClose();
                setShowContactModal(true);
                trackContactClicked("apartments-in-bloomington");
            }}>contact</a></h3>
        </nav>
    )
};

export interface HeaderNavProps extends NavProps {
    modalClose: () => void;
}
