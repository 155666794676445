import React, {useEffect, useState} from "react";
import {
    FloorplanCardData,
    FloorplanSectionSkeleton,
    FloorplansSection
} from "@renaissancerentals/renaissance-component";
import "./assets/Floorplans.scss";
import {
    AVAILABLE_NOW,
    getAllPropertyFilterData
} from "@renaissancerentals/renaissance-component/lib/floorplan/service/FloorplanService";
import moment from "moment";
import {renaissance} from "../../data/RenaissanceData";

export const Floorplans: React.FC = () => {
    const [floorplans, setFloorplans] = useState<FloorplanCardData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {

        getAllPropertyFilterData().then(properties => {
            const floorplans: FloorplanCardData[] = [];
            properties.filter(property => !property.name.toLowerCase().includes("garage")).forEach(property => {
                property.floorplans.forEach(floorplan => {
                    floorplans.push(floorplan);
                });
            });
            setFloorplans(floorplans);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);
    return (
        <div className="floorplans">
            {
                isLoading ?
                    <FloorplanSectionSkeleton/> :
                    <FloorplansSection floorplans={floorplans}
                                       defaultAvailability={AVAILABLE_NOW}
                                       propertyId={renaissance.propertyId}
                                       title="search apartments"/>
            }
        </div>);
};
