import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {SubletsSection} from "@renaissancerentals/renaissance-component";
import {MainLayout} from "../components/layout/MainLayout";

export const SubletsPage: React.FC = () => {
    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);

    return (
        <>
            <Helmet>
                <title>Renaissance Rentals: Sublets</title>
            </Helmet>
            <MainLayout isMainPage={false}
                        showApplicationModal={showApplicationModal} showContactModal={showContactModal}
                        setShowApplicationModal={setShowApplicationModal} setShowContactModal={setShowContactModal}>
                <SubletsSection linkToSubletCreatePage="/sublets/new"/>
            </MainLayout>
        </>

    );
}