import React from "react";
import {NavProps} from "./NavProps";
import {renaissance} from "../../data/RenaissanceData";
import {trackContactClicked} from "@renaissancerentals/renaissance-component";

export const FooterNav: React.FC<NavProps> = (
    {setShowApplicationModal, setShowContactModal}) => {
    return (
        <ul>
            <li><a href="#apply" onClick={() => setShowApplicationModal(true)}>apply</a></li>
            <li><a href="#contact" onClick={() => {
                setShowContactModal(true);
                trackContactClicked("apartments-in-bloomington");
            }}>contact</a></li>
            <li><a href={"tel:" + renaissance.contact.number}>{renaissance.contact.display}</a></li>
        </ul>
    );
}
